import React from 'react';
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Image,
} from '@chakra-ui/react';
const ModalTips = (props) => {
	const { image, category, fullname, content, id, count } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();

	const viewTips = (id) => {
		onOpen();
	};

	const publishArticle = (id) => {
		const myHeaders = new Headers();
		const tokenUser = localStorage.getItem('accessToken');
		myHeaders.append('Access-Control-Allow-Origin', '*');
		myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
		myHeaders.append('Authorization', `Bearer ${tokenUser}`);

		const raw = JSON.stringify({isPublished: true});
		const requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
			body: raw
		};
		fetch(
			`${process.env.REACT_APP_URL_SERVER}/v1/writes/${id}`,
			requestOptions
		)
			.then((response) => {
				return response;
			})
			.then((response) => {
				window.location.reload();
			})
			.catch(function (error) {
				console.error(error);
			});
	};
	return (
		<div>
			<Button
				colorScheme={'blue'}
				color="white"
				fontSize="sm"
				fontWeight="500"
				borderRadius="70px"
				px="24px"
				py="5px"
				onClick={() => viewTips(id)}
			>
				Voir plus
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Catégorie: {category}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<div
							style={{
								justifyContent: 'center',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Image src={image} width="30%" borderRadius="20px" />
						</div>
						<div style={{ marginTop: '40px' }}>
							<ModalHeader>Nom & Prénom: {fullname}</ModalHeader>
							<ModalHeader>
								Note : {category === 'Review' ? `${count} étoiles sur 5 étoiles` : ''}
							</ModalHeader>
							<ModalHeader>Description: {content}</ModalHeader>
						</div>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Fermer
						</Button>
						<Button colorScheme="blue" mr={3} onClick={() => publishArticle(id)}>
							Publier
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</div>
	);
};

export default ModalTips;
