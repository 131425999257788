import React, { useState } from "react";
import axios from "axios";
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Flex,
} from "@chakra-ui/react";

export default function ModalAddArtist() {
    const [isOpen, setIsOpen] = useState(false);
    const tokenUser = localStorage.getItem("accessToken");
    const [artistData, setArtistData] = useState({
        artistName: "",
        firstname: "",
        lastname: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setArtistData({
            ...artistData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const trimmedArtist = {
            artistName: artistData.artistName.trim(),
            firstname: artistData.firstname.trim(),
            lastname: artistData.lastname.trim(),
        };

        const filteredArtist = {
            artistName: trimmedArtist.artistName.length ? trimmedArtist.artistName : undefined,
            firstname: trimmedArtist.firstname.length ? trimmedArtist.firstname : undefined,
            lastname: trimmedArtist.lastname.length ? trimmedArtist.lastname : undefined,
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_URL_SERVER}/v1/artists`,
                filteredArtist,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json; charset=UTF-8",
                        Authorization: `Bearer ${tokenUser}`,
                    },
                }
            );
            if (response.status === 201) {
                setArtistData({
                    artistName: "",
                    firstname: "",
                    lastname: "",
                });
                setIsOpen(false);
                window.location.reload();
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'artiste : ", error);
        }
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)} colorScheme="blue">
                Ajouter un artiste
            </Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize={25}>Ajouter un artiste</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={3} mt={5}>
                                <FormControl isRequired >
                                    <FormLabel>Nom d'artiste</FormLabel>
                                    <Input
                                        type="text"
                                        name="artistName"
                                        placeholder="Nom d'artiste"
                                        value={artistData.artistName}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormControl>
                                <FormControl >
                                    <FormLabel >Prénom</FormLabel>
                                    <Input
                                        type="text"
                                        name="firstname"
                                        placeholder="Prénom de l'artiste"
                                        value={artistData.firstname}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Nom de famille</FormLabel>
                                    <Input
                                        type="text"
                                        name="lastname"
                                        placeholder="Nom de famille de l'artiste"
                                        value={artistData.lastname}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Stack>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button colorScheme="red" mr={3} onClick={() => setIsOpen(false)}>
                                Fermer
                            </Button>
                            <Button colorScheme="teal" onClick={handleSubmit}>
                                Ajouter l'artiste
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
