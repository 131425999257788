/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Select,
  FormLabel,
  Stack,
  FormControl,
  Textarea,
  Image,
  Icon,
  Checkbox,
} from "@chakra-ui/react";

import axios from "axios";
import { SketchPicker } from "react-color";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import { getInnerHtml } from "text-editor-react";
import formatHtml from "utils/formatHtml";

const ModalEditProduct = (props) => {
  const { artists, categories } = props;
  const tokenUser = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const detailsContentId = "details-content";
  const [productData, setProductData] = useState({
    category: props.category,
    title: props.title,
    subtitle: props.subtitle,
    colors: props.colors,
    description: props.description,
    details: props.details,
    price: props.price,
    stock: props.stock,
    customFields: props.customFields || [{ name: "", value: "" }],
    noColors: props.noColors,
    isEvent: props.isEvent,
    linkEvent: props.linkEvent,
    artistId: props.artistId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [fieldName, subFieldName] = name.split(".");
    let processedValue = value;

    if (name === "artistId" && value === "") {
      processedValue = null;
    }

    if (subFieldName) {
      setProductData({
        ...productData,
        [fieldName]: {
          ...productData[fieldName],
          [subFieldName]: processedValue,
        },
      });
    } else {
      setProductData({
        ...productData,
        [name]: processedValue,
      });
    }
  };

  const addCustomField = () => {
    setProductData({
      ...productData,
      customFields: [...productData.customFields, { name: "", value: "" }],
    });
  };

  const removeCustomField = (index) => {
    const newCustomField = [...productData.customFields];
    newCustomField.splice(index, 1);
    setProductData({
      ...productData,
      customFields: newCustomField,
    });
  };

  const handleChangeCustomFields = (index, key, value) => {
    const newCustomField = [...productData.customFields];
    newCustomField[index][key] = value;
    setProductData({
      ...productData,
      customFields: newCustomField,
    });
  };

  const handleColorChange = (index, key, value) => {
    const newColors = [...productData.colors];
    newColors[index][key] = value;
    setProductData({
      ...productData,
      colors: newColors,
    });
  };

  const addColorField = () => {
    setProductData({
      ...productData,
      colors: [...productData.colors, { name: "", image: "" }],
    });
  };

  const removeColorField = (index) => {
    const newColors = [...productData.colors];
    newColors.splice(index, 1);
    setProductData({
      ...productData,
      colors: newColors,
    });
  };

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    try {
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
        formData
      );
      const imageUrl = response.data.data.url;

      const newColors = [...productData.colors];
      newColors[index].image = imageUrl;
      setProductData({
        ...productData,
        colors: newColors,
      });
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productDetails = getInnerHtml(detailsContentId);

    if (productData.noColors) {
      const colorsWithoutNames = productData.colors.map((color) => ({
        ...color,
        name: "",
      }));
      setProductData({ ...productData, colors: colorsWithoutNames });
    }
    try {
      const artistId =
        typeof productData.artistId === "object" && productData.artistId
          ? productData.artistId?.id
          : productData.artistId ?? null;

      const response = await axios.patch(
        `${process.env.REACT_APP_URL_SERVER}/v1/products/${props.id}`,
        {
          ...productData,
          details: productDetails,
          artistId: artistId,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${tokenUser}`,
          },
        }
      );
      if (response.status === 200) {
        setProductData({
          category: "",
          title: "",
          subtitle: "",
          colors: [{ name: "", image: "" }],
          description: "",
          details: "",
          price: 0,
          stock: 0,
          customFields: [{ name: "", value: "" }],
          noColors: false,
          isEvent: false,
          linkEvent: "",
          artistId: null,
        });
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit : ", error);
    }
  };

  return (
    <>
      <Icon
        w="20px"
        h="20px"
        me="5px"
        style={{ cursor: "pointer" }}
        as={FaPencilAlt}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier le produit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormLabel fontSize={20} fontWeight={600}>
                Informations de base
              </FormLabel>
              <FormLabel color={"gray"} fontWeight={400} mt={-2}>
                Section pour configurer les informations de base sur le produit
              </FormLabel>
              <Stack spacing={3} mt={5}>
                <FormControl>
                  <FormLabel>Nom du produit</FormLabel>
                  <Input
                    type="text"
                    name="title"
                    placeholder="Nom du produit"
                    value={productData.title}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Sous-titre du produit</FormLabel>
                  <Input
                    type="text"
                    name="subtitle"
                    placeholder="Sous-titre du produit"
                    value={productData.subtitle}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mt={3}>Catégorie</FormLabel>
                  <Select
                    id="category"
                    name="category"
                    defaultValue={productData.category}
                    onChange={handleChange}
                    required
                    cursor={"pointer"}
                  >
                    {categories.map((category, index) => (
                      <option key={index} value={category.category}>
                        {category.category}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel mt={3}>Artiste</FormLabel>
                  <Select
                    id="artistId"
                    name="artistId"
                    defaultValue={productData.artistId?.id || null}
                    onChange={handleChange}
                    cursor={"pointer"}
                    placeholder="Artiste"
                  >
                    {artists.map((artist) => (
                      <option key={artist.id} value={artist.id}>
                        {artist.artistName}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel mt={3}>Description</FormLabel>
                  <Textarea
                    type="text"
                    name="description"
                    placeholder="Description du produit"
                    value={productData.description}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <hr style={{ marginTop: "30px" }} />
                <FormLabel mt={5} fontSize={20} fontWeight={600}>
                  Tarif du produit
                </FormLabel>
                <FormLabel color={"gray"} fontWeight={400} mt={0}>
                  Section pour configurer les informations de vente de produits
                </FormLabel>
                <FormControl>
                  <FormLabel mt={0}>Prix</FormLabel>
                  <Input
                    type="number"
                    name="price"
                    placeholder="Prix du produit"
                    value={productData.price}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mt={3}>Stock</FormLabel>
                  <Input
                    type="number"
                    placeholder="Stock disponible"
                    name="stock"
                    value={productData.stock}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <hr style={{ marginTop: "30px" }} />
                <Box>
                  <FormLabel mt={3} fontSize={20} fontWeight={600}>
                    Détails du produit
                  </FormLabel>
                  <FormLabel color={"gray"} fontWeight={400} mt={-2}>
                    Section pour configurer les détails sur le produit
                  </FormLabel>
                  <>
                    {productData.customFields.map((customField, index) => (
                      <Stack
                        key={index}
                        direction="row"
                        spacing={3}
                        mt={4}
                        alignItems={"center"}
                      >
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Titre"
                            value={customField.name}
                            onChange={(e) =>
                              handleChangeCustomFields(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                            required
                          />
                        </FormControl>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Sous-titre"
                            value={customField.value}
                            onChange={(e) =>
                              handleChangeCustomFields(
                                index,
                                "value",
                                e.target.value
                              )
                            }
                            required
                          />
                        </FormControl>
                        <Button
                          colorScheme="red"
                          px={2}
                          mx={2}
                          onClick={() => removeCustomField(index)}
                        >
                          <FaTrash />
                        </Button>
                      </Stack>
                    ))}
                    <Button onClick={addCustomField} mt={2}>
                      Ajouter un champs personnalisé
                    </Button>
                  </>
                  <FormControl mt={3}>
                    <Checkbox
                      id="isEvent"
                      name="isEvent"
                      isChecked={productData.isEvent}
                      onChange={(e) =>
                        setProductData({
                          ...productData,
                          isEvent: e.target.checked,
                        })
                      }
                    >
                      Évènement
                    </Checkbox>
                    {productData.isEvent && (
                      <Input
                        type="text"
                        name="linkEvent"
                        placeholder="Lien vers l'événement"
                        value={productData.linkEvent}
                        onChange={handleChange}
                        required
                      />
                    )}
                  </FormControl>
                </Box>
                <FormControl>
                  <FormLabel mt={3}>Détails additionels</FormLabel>
                  <div
                    className="react-text-editor"
                    id={detailsContentId}
                    contentEditable={true}
                    style={{
                      fontFamily: "Epilogue",
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "black",
                      letterSpacing: "0px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: formatHtml(props.details),
                    }}
                  ></div>
                </FormControl>
                <FormControl mt={3}>
                  <Checkbox
                    defaultChecked={productData.noColors}
                    onChange={() =>
                      setProductData({
                        ...productData,
                        noColors: !productData.noColors,
                      })
                    }
                  >
                    Pas de couleurs
                  </Checkbox>
                </FormControl>
                {productData.colors.map((color, index) => (
                  <Box key={index}>
                    <FormLabel mt={3}>Couleur {index + 1}</FormLabel>
                    <Stack direction="row" spacing={3} alignItems={"center"}>
                      <FormControl>
                        <SketchPicker
                          color={color.name}
                          onChange={(value) =>
                            handleColorChange(index, "name", value.hex)
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Image de la couleur {index + 1}</FormLabel>
                        <Input
                          type="file"
                          onChange={(e) => handleImageUpload(e, index)}
                          accept="image/*"
                          required
                        />
                        {color.image && (
                          <Image src={color.image} alt={`Color ${index + 1}`} />
                        )}
                      </FormControl>
                      <Button
                        colorScheme="red"
                        px={2}
                        mx={2}
                        onClick={() => removeColorField(index)}
                      >
                        <FaTrash />
                      </Button>
                    </Stack>
                  </Box>
                ))}
                <Button onClick={addColorField}>Ajouter une couleur</Button>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Mettre à jour le produit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalEditProduct;
