/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	SimpleGrid,
	Icon,
} from '@chakra-ui/react';

import CommunityCard from 'components/card/Community';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import ModalCommunity from './components/ModalCommunity';

export default function Community() {
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const [tips, setTips] = useState([]);
	const tokenUser = localStorage.getItem('accessToken');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalUsers, setTotalUsers] = useState(0);
	const myHeaders = new Headers();
	myHeaders.append('Access-Control-Allow-Origin', '*');
	myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
	myHeaders.append('Authorization', `Bearer ${tokenUser}`);
	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
	};
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_URL_SERVER}/v1/community?page=${page}`,
			requestOptions
		)
			.then((response) => {
				return response.json();
			})
			.then((response) => {
				setTips(response.results);
				setTotalPages(response.totalPages);
				setTotalUsers(response.totalResults);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, [page]);

	function handlePrevPage() {
		setPage((prevPage) => prevPage - 1);
	}

	function handleNextPage() {
		setPage((prevPage) => prevPage + 1);
	}

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<ModalCommunity />
			<Flex
				flexDirection="column"
				gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
			>
				{/* <Banner /> */}
				<Flex direction="column">
					<Flex
						mt="45px"
						mb="20px"
						justifyContent="space-between"
						direction={{ base: 'column', md: 'row' }}
						align={{ base: 'start', md: 'center' }}
					>
						<Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
							Photos communautaires
						</Text>
					</Flex>
					<SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
						{tips
							? tips.map((tips, index) => {
									return <CommunityCard key={tips.id} image={tips.image} id={tips.id} />;
							  })
							: null}
					</SimpleGrid>
				</Flex>
			</Flex>
			{/* Delete Product */}
			<Flex
				style={{
					justifyContent: 'space-between',
					paddingLeft: '20px',
					paddingRight: '20px',
					marginTop: '35px',
				}}
			>
				<Text
					color={textColor}
					fontSize="15px"
					fontWeight="700"
					lineHeight="100%"
					style={{ marginTop: '10px' }}
				>
					Nombre de résultat : {totalUsers} photos.
				</Text>
				<div>
					<Flex style={{ gap: '20px' }}>
						<Button
							variant="brand"
							color="white"
							fontSize="sm"
							fontWeight="500"
							borderRadius="70px"
							onClick={handlePrevPage}
							disabled={page === 1}
							style={{ padding: '0px' }}
						>
							<Icon w="24px" h="24px" color={'white'} as={MdNavigateBefore} />
						</Button>
						<Text
							color={textColor}
							fontSize="15px"
							fontWeight="700"
							lineHeight="100%"
							style={{ marginTop: '10px' }}
						>
							Page {page} sur {totalPages}
						</Text>
						<Button
							variant="brand"
							color="white"
							fontSize="sm"
							fontWeight="500"
							borderRadius="70px"
							onClick={handleNextPage}
							disabled={page === totalPages}
							style={{ padding: '0px' }}
						>
							<Icon w="24px" h="24px" color={'white'} as={MdNavigateNext} />
						</Button>
					</Flex>
				</div>
			</Flex>
		</Box>
	);
}
