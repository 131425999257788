import { Box } from "@chakra-ui/react";
import React from "react";
import CategoryTable from "../dataTables/components/CategoryTable";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import { columnsDataCategories } from "../default/variables/columnsData";
import ModalAddCategory from "./ModalAddCategory";

const CategoryPage = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ModalAddCategory />
      <CategoryTable
        columnsData={columnsDataCategories}
        tableData={tableDataCheck}
      />
      <br />
    </Box>
  );
};

export default CategoryPage;
