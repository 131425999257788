/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
} from '@chakra-ui/react';

import axios from 'axios';

const ModalEditTips = (props) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [largeImageProps, setLargeImage] = React.useState('');
	const [articleData, setArticleData] = React.useState({
		fullname: '',
		count: '',
		category: '',
		content: '',
	});
	useEffect(() => {
		setLargeImage(props.image);
	}, []);
	const handleChange = (e) => {
		const { id, value } = e.target;
		setArticleData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};
	const saveContent = () => {
		const myHeaders = new Headers();
		const tokenUser = localStorage.getItem('accessToken');
		myHeaders.append('Access-Control-Allow-Origin', '*');
		myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
		myHeaders.append('Authorization', `Bearer ${tokenUser}`);

		const raw = JSON.stringify({
			fullname: articleData.fullname === '' ? props.fullname : articleData.fullname,
			count:
				articleData.count === '' ? props.count : articleData.count,
			category:
				articleData.category === '' ? props.category : articleData.category,
			image: largeImageProps,
			content: articleData.content === '' ? props.content : articleData.content,
		});
		const requestOptions = {
			method: 'PATCH',
			headers: myHeaders,
			body: raw,
		};
		fetch(
			`${process.env.REACT_APP_URL_SERVER}/v1/writes/${props.id}`,
			requestOptions
		)
			.then((response) => {
				return response;
			})
			.then((response) => {
				window.location.reload();
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	const uploadLargeImage = async (image) => {
		const formData = new FormData();
		formData.append('image', image);

		const response = await axios.post(
			`https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
			formData
		);
		setLargeImage(response.data.data.url);
	};
	return (
		<>
			<Button
				fontSize="sm"
				colorScheme={'yellow'}
				color="white"
				fontWeight="500"
				px="24px"
				py="5px"
				onClick={onOpen}
			>
				Modifier
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Modifier l'avis / tips</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Input
							id="fullname"
							defaultValue={props.fullname}
							onChange={handleChange}
							placeholder="Nom complet"
						/>
						<Input
							id="count"
							defaultValue={props.count}
							onChange={handleChange}
							mt="20px"
							placeholder="Note sur 5"
						/>
						<Input
							id="content"
							defaultValue={props.content}
							onChange={handleChange}
							mt="20px"
							placeholder="Contenu"
						/>
						<div style={{ marginTop: '20px' }}>
							<label htmlFor="largeImage">Image</label>
							{largeImageProps.length === 0 ? (
								<Input
									id="largeImage"
									type="file"
									accept="image/*"
									onChange={(event) => uploadLargeImage(event.target.files[0])}
									placeholder="Lien de l'image"
								/>
							) : (
								<Flex style={{ position: 'relative' }}>
									<img src={largeImageProps} alt="largeImage" />
									<Button
										colorScheme="red"
										onClick={() => setLargeImage('')}
										style={{ zIndex: '99999', position: 'absolute', top: '0' }}
									>
										supprimer
									</Button>
								</Flex>
							)}
						</div>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Annuler
						</Button>
						<Button colorScheme="blue" onClick={saveContent}>
							Mettre à jour l'avis / tips
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ModalEditTips;
