/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// Chakra imports
import {
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	SimpleGrid,
	Icon,
} from '@chakra-ui/react';

import Article from 'components/card/Article';

import ModalArticle from './components/Modal';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';

export default function Marketplace() {
	// Chakra Color Mode
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.500', 'white');
	const [articles, setArticles] = useState([]);
	const tokenUser = localStorage.getItem('accessToken');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalUsers, setTotalUsers] = useState(0);
	const [artists, setArtists] = useState([]);
	const myHeaders = new Headers();
	myHeaders.append('Access-Control-Allow-Origin', '*');
	myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
	myHeaders.append('Authorization', `Bearer ${tokenUser}`);
	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
	};

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_URL_SERVER}/v1/artists`,
			requestOptions
		)
			.then((response) => {
				return response.json();
			})
			.then((response) => {
				setArtists(response.results);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, []);


	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_URL_SERVER}/v1/articles?page=${page}`,
			requestOptions
		)
			.then((response) => {
				return response.json();
			})
			.then((response) => {
				setArticles(response.results);
				setTotalPages(response.totalPages);
				setTotalUsers(response.totalResults);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, [page]);

	const getCategory = (category) => {
		fetch(
			`${process.env.REACT_APP_URL_SERVER}/v1/articles?page=${page}&category=${category}`,
			requestOptions
		)
			.then((response) => {
				return response.json();
			})
			.then((response) => {
				setArticles(response.results);
				setTotalPages(response.totalPages);
				setTotalUsers(response.totalResults);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	function handlePrevPage() {
		setPage((prevPage) => prevPage - 1);
	}

	function handleNextPage() {
		setPage((prevPage) => prevPage + 1);
	}

	return (
		<Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<ModalArticle artists={artists} />

			<Flex
				flexDirection="column"
				gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
			>
				{/* <Banner /> */}
				<Flex direction="column">
					<Flex
						mt="45px"
						mb="20px"
						justifyContent="space-between"
						direction={{ base: 'column', md: 'row' }}
						align={{ base: 'start', md: 'center' }}
					>
						<Text color={textColor} fontSize="2xl" ms="24px" fontWeight="700">
							Articles Mr.Nini
						</Text>
						<Flex
							align="center"
							me="20px"
							ms={{ base: '24px', md: '0px' }}
							mt={{ base: '20px', md: '0px' }}
						>
							<Button
								color={textColorBrand}
								fontWeight="500"
								me={{ base: '34px', md: '44px' }}
								to="#art"
								onClick={() => {
									getCategory('Tips');
								}}
							>
								Tips
							</Button>
							<Button
								color={textColorBrand}
								fontWeight="500"
								me={{ base: '34px', md: '44px' }}
								to="#music"
								onClick={() => {
									getCategory('Culture');
								}}
							>
								Culture
							</Button>
							<Button
								color={textColorBrand}
								fontWeight="500"
								me={{ base: '34px', md: '44px' }}
								to="#collectibles"
								onClick={() => {
									getCategory('Éducation');
								}}
							>
								Éducation
							</Button>
							<Button
								color={textColorBrand}
								fontWeight="500"
								me={{ base: '34px', md: '44px' }}
								to="#collectibles"
								onClick={() => {
									getCategory('Bien-être');
								}}
							>
								Bien-être
							</Button>
							<Button
								color={textColorBrand}
								fontWeight="500"
								me={{ base: '34px', md: '44px' }}
								to="#collectibles"
								onClick={() => {
									getCategory('Balade');
								}}
							>
								Balade
							</Button>
							<Button
								color={textColorBrand}
								fontWeight="500"
								to="#sports"
								onClick={() => {
									getCategory('Artistes');
								}}
							>
								Artistes
							</Button>
						</Flex>
					</Flex>
					<SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
						{articles
							? articles.map((article) => {
								return (
									<Article
										key={article.id}
										title={article.title}
										subtitle={article.subtitle}
										smallImage={article.smallImage}
										largeImage={article.largeImage}
										category={article.category}
										optionalImage={article.optionalImage}
										id={article.id}
										isPublished={article.isPublished}
										artistId={article.artistId}
										content={article.content}
										artists={artists}
									/>
								);
							})
							: null}
					</SimpleGrid>
				</Flex>
			</Flex>
			{/* Delete Product */}
			<Flex
				style={{
					justifyContent: 'space-between',
					paddingLeft: '20px',
					paddingRight: '20px',
					marginTop: '35px',
				}}
			>
				<Text
					color={textColor}
					fontSize="15px"
					fontWeight="700"
					lineHeight="100%"
					style={{ marginTop: '10px' }}
				>
					Nombre de résultat : {totalUsers} articles.
				</Text>
				<div>
					<Flex style={{ gap: '20px' }}>
						<Button
							variant="brand"
							color="white"
							fontSize="sm"
							fontWeight="500"
							borderRadius="70px"
							onClick={handlePrevPage}
							disabled={page === 1}
							style={{ padding: '0px' }}
						>
							<Icon w="24px" h="24px" color={'white'} as={MdNavigateBefore} />
						</Button>
						<Text
							color={textColor}
							fontSize="15px"
							fontWeight="700"
							lineHeight="100%"
							style={{ marginTop: '10px' }}
						>
							Page {page} sur {totalPages}
						</Text>
						<Button
							variant="brand"
							color="white"
							fontSize="sm"
							fontWeight="500"
							borderRadius="70px"
							onClick={handleNextPage}
							disabled={page === totalPages}
							style={{ padding: '0px' }}
						>
							<Icon w="24px" h="24px" color={'white'} as={MdNavigateNext} />
						</Button>
					</Flex>
				</div>
			</Flex>
		</Box>
	);
}
