import React from 'react';
import { NavLink } from 'react-router-dom';
// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

function SignIn() {
	// Chakra color mode
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorSecondary = 'gray.400';
	const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
	const textColorBrand = useColorModeValue('brand.500', 'white');
	const brandStars = useColorModeValue('brand.500', 'brand.400');
	const [show, setShow] = React.useState(false);
	const [userData, setUserData] = React.useState({
		email: '',
		password: '',
	});

	const handleChange = (e) => {
		const { id, value } = e.target;
		setUserData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};
	const postDataUser = () => {
		const myHeaders = new Headers();
		myHeaders.append('Access-Control-Allow-Origin', '*');
		myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
		const raw = JSON.stringify({
			email: userData.email,
			password: userData.password,
		});
		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
		};

		fetch(`${process.env.REACT_APP_URL_SERVER}/v1/auth/login`, requestOptions)
			.then((response) => response.json())
			.then((response) => {
				if (response.user.role === 'admin') {
					localStorage.setItem('firstname', response.user.firstname);
					document.cookie = `token=${response.tokens.access.token}; path=/;`;
					localStorage.setItem('accessToken', response.tokens.access.token);
					localStorage.setItem('refreshToken', response.tokens.refresh.token);
					window.location.href = '/admin/default';
				}
				if (response.user.role !== 'admin') {
					toast.error("Vous n'êtes pas autorisé à vous connecter !");
				}	
				if (response.code === 401) {
					toast.error('Le mot de passe ou e-mail est incorrect !');
				}
			})
			.catch(function (error) {
				toast.error('Le mot de passe ou e-mail est incorrect !');
			});
	};

	const handleClick = () => setShow(!show);
	return (
		<Flex
			w="100%"
			me="auto"
			alignItems="center"
			justifyContent="center"
			mb={{ base: '30px', md: '60px' }}
			px={{ base: '25px', md: '0px' }}
			mt={{ base: '40px', md: '14vh' }}
			flexDirection="column"
		>
			<ToastContainer />
			<Box me="">
				<Heading color={textColor} fontSize="36px" mb="10px">
					Connexion
				</Heading>
				<Text
					mb="36px"
					ms="4px"
					color={textColorSecondary}
					fontWeight="400"
					fontSize="md"
				>
					Entrer votre e-mail et votre mot de passe pour se connecter !
				</Text>
			</Box>
			<Flex
				zIndex="2"
				direction="column"
				w={{ base: '100%', md: '420px' }}
				background="transparent"
				borderRadius="15px"
				mx={{ base: 'auto', lg: 'unset' }}
				me="auto"
				mb={{ base: '20px', md: 'auto' }}
			>
				<FormControl>
					<FormLabel
						display="flex"
						ms="4px"
						fontSize="sm"
						fontWeight="500"
						color={textColor}
						mb="8px"
					>
						E-mail<Text color={brandStars}>*</Text>
					</FormLabel>
					<Input
						isRequired={true}
						variant="auth"
						fontSize="sm"
						id="email"
						defaultValue={userData.email}
						onChange={handleChange}
						ms={{ base: '0px', md: '0px' }}
						type="email"
						placeholder="nini@monsieur.com"
						mb="24px"
						fontWeight="500"
						size="lg"
					/>
					<FormLabel
						ms="4px"
						fontSize="sm"
						fontWeight="500"
						color={textColor}
						display="flex"
					>
						Mot de passe<Text color={brandStars}>*</Text>
					</FormLabel>
					<InputGroup size="md">
						<Input
							isRequired={true}
							fontSize="sm"
							id="password"
							defaultValue={userData.password}
							onChange={handleChange}
							placeholder="Min. 8 characters"
							mb="24px"
							size="lg"
							type={show ? 'text' : 'password'}
							variant="auth"
						/>
						<InputRightElement display="flex" alignItems="center" mt="4px">
							<Icon
								color={textColorSecondary}
								_hover={{ cursor: 'pointer' }}
								as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
								onClick={handleClick}
							/>
						</InputRightElement>
					</InputGroup>
					<Button
						fontSize="sm"
						variant="brand"
						fontWeight="500"
						w="100%"
						h="50"
						onClick={postDataUser}
						mb="24px"
					>
						Connexion
					</Button>
				</FormControl>
				<Flex
					flexDirection="column"
					justifyContent="center"
					alignItems="start"
					maxW="100%"
					mt="0px"
				>
					<Text color={textColorDetails} fontWeight="400" fontSize="14px">
						Pas encore de compte ?
						<NavLink to="/auth/register">
							<Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
								S'inscrire
							</Text>
						</NavLink>
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default SignIn;
