/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdPersonSearch } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import { FaStripe } from "react-icons/fa";
import { FaMailchimp } from "react-icons/fa";
import { SiDatadog, SiGooglesearchconsole, SiGoogleanalytics } from "react-icons/si";

export default function UserReports() {
  const tokenUser = localStorage.getItem("accessToken");
  const [lastTenDays, setLastTenDays] = useState(0);
  const [thisMonth, setthisMonth] = useState(0);
  const [today, setToday] = useState(0);
  const [total, setTotal] = useState(0);

  const [lastOrderTenDays, setLastOrderTenDays] = useState(0);
  const [thisOrderMonth, setOrderthisMonth] = useState(0);
  const [todayOrder, setTodayOrder] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);

  const [lastProductTenDays, setLastProductTenDays] = useState(0);
  const [thisProductMonth, setProductthisMonth] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [todayProduct, setTodayProduct] = useState(0);
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/users/counts/all`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setLastTenDays(response.lastTenDays);
        setthisMonth(response.thisMonth);
        setToday(response.today);
        setTotal(response.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/products/counts/all`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setLastProductTenDays(response.lastTenDays);
        setProductthisMonth(response.thisMonth);
        setTodayProduct(response.today);
        setTotalProduct(response.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/orders/counts/all`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setLastOrderTenDays(response.lastTenDays);
        setOrderthisMonth(response.thisMonth);
        setTodayOrder(response.today);
        setTotalOrder(response.total);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Text color="" fontSize="xl" fontWeight="800" mt="12" ml="2" mb="5">
        Liens utiles
      </Text>
      <Flex justifyContent={"center"} gap={4}>
        <Button
          bgColor="#F59E0B"
          color="white"
          onClick={() =>
            window.open(
              "https://analytics.google.com/analytics/web/#/p353290745/realtime/overview",
              "_blank"
            )
          }
        >
          <SiGoogleanalytics />
        </Button>
        <Button
          bgColor="#10b981"
          color="white"
          onClick={() =>
            window.open(
              "https://search.google.com/search-console?resource_id=sc-domain%3Amonsieurnini.com",
              "_blank"
            )
          }
        >
          <SiGooglesearchconsole />
        </Button>
        <Button
          colorScheme="purple"
          onClick={() =>
            window.open("https://dashboard.stripe.com/dashboard", "_blank")
          }
        >
          <FaStripe size={40} />
        </Button>
        <Button
          colorScheme="yellow"
          color="white"
          onClick={() =>
            window.open(
              "https://us18.admin.mailchimp.com/?referrer=%2F",
              "_blank"
            )
          }
        >
          <FaMailchimp color="black" size={25} />
        </Button>
        <Button
          colorScheme="blue"
          color="white"
          onClick={() => window.open("https://www.monsieurnini.com", "_blank")}
        >
          <SiDatadog />
        </Button>
      </Flex>
      <Text color="" fontSize="xl" fontWeight="800" mt="12" ml="2" mb="5">
        Statistiques Commandes
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={FaFileInvoiceDollar}
                  color={brandColor}
                />
              }
            />
          }
          name="Commandes du jour"
          value={todayOrder}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={FaFileInvoiceDollar}
                  color={brandColor}
                />
              }
            />
          }
          name="Commandes des 7 derniers jours"
          value={lastOrderTenDays}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={FaFileInvoiceDollar}
                  color={brandColor}
                />
              }
            />
          }
          name="Commandes des 30 derniers jours"
          value={thisOrderMonth}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={FaFileInvoiceDollar}
                  color={brandColor}
                />
              }
            />
          }
          name="Total commandes"
          value={totalOrder}
        />
      </SimpleGrid>

      <Text color="" fontSize="xl" fontWeight="800" mt="12" ml="2" mb="5">
        Statistiques Revenus
      </Text>
      <SimpleGrid gap="20px" mb="20px">
        <TotalSpent />
      </SimpleGrid>
      <Text color="" fontSize="xl" fontWeight="800" mt="12" ml="2" mb="5">
        Statistiques Produits
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon w="25px" h="25px" as={FaShoppingBag} color={brandColor} />
              }
            />
          }
          name="Produits du jour"
          value={todayProduct}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon w="25px" h="25px" as={FaShoppingBag} color={brandColor} />
              }
            />
          }
          name="Produits des 7 derniers jours"
          value={lastProductTenDays}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon w="25px" h="25px" as={FaShoppingBag} color={brandColor} />
              }
            />
          }
          name="Produits des 30 derniers jours"
          value={thisProductMonth}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon w="25px" h="25px" as={FaShoppingBag} color={brandColor} />
              }
            />
          }
          name="Total produits"
          value={totalProduct}
        />
      </SimpleGrid>
      <Text color="" fontSize="xl" fontWeight="800" mt="12" ml="2" mb="5">
        Statistiques Utilisateurs
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={MdPersonSearch}
                  color={brandColor}
                />
              }
            />
          }
          name="Utilisateurs du jour"
          value={today}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={MdPersonSearch}
                  color={brandColor}
                />
              }
            />
          }
          name="Utilisateurs des 7 derniers jours"
          value={lastTenDays}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={MdPersonSearch}
                  color={brandColor}
                />
              }
            />
          }
          name="Utilisateurs des 30 derniers jours"
          value={thisMonth}
        />
        <MiniStatistics
          style={{ height: "180px" }}
          startContent={
            <IconBox
              w="100px"
              h="100px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={MdPersonSearch}
                  color={brandColor}
                />
              }
            />
          }
          name="Total utilisateurs"
          value={total}
        />
      </SimpleGrid>
    </Box>
  );
}
