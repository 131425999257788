import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Flex,
  Textarea,
} from "@chakra-ui/react";

const ModalAddCategory = () => {
  const [isOpen, setIsOpen] = useState(false);
  const tokenUser = localStorage.getItem("accessToken");
  const [categoryData, setCategoryData] = useState({
    category: "",
    descriptionFr: "",
    descriptionEn: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({
      ...categoryData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_SERVER}/v1/categories`,
        categoryData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${tokenUser}`,
          },
        }
      );
      if (response.status === 201) {
        setCategoryData({
          category: "",
          descriptionFr: "",
          descriptionEn: "",
        });
        setIsOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit : ", error);
    }
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} colorScheme="blue">
        Ajouter une catégorie produit
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={25}>Ajouter une catégorie produit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mt={5}>
                <FormControl>
                  <FormLabel>Nom du produit</FormLabel>
                  <Input
                    type="text"
                    name="category"
                    placeholder="Nom de la catégorie produit"
                    value={categoryData.category}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description en français</FormLabel>
                  <Textarea
                    type="text"
                    name="descriptionFr"
                    placeholder="Description en français"
                    value={categoryData.descriptionFr}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description en anglais</FormLabel>
                  <Textarea
                    type="text"
                    name="descriptionEn"
                    placeholder="Description en anglais"
                    value={categoryData.descriptionEn}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent={"space-between"}>
              <Button colorScheme="red" mr={3} onClick={() => setIsOpen(false)}>
                Fermer
              </Button>
              <Button colorScheme="teal" onClick={handleSubmit}>
                Ajouter la catégorie produit
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalAddCategory;
