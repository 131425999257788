import { Box, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ModalAddProduct from "./ModalAddProduct";
import ProductTable from "../dataTables/components/ProductTable";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import { columnsDataProducts } from "../default/variables/columnsData";
import axios from "axios";

const ProductPage = () => {
  const tokenUser = localStorage.getItem("accessToken");
  const [artists, setArtists] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingArtists, setLoadingArtists] = useState(true);

  const axiosHeaders = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${tokenUser}`,
    },
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/categories?limit=100`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setCategories(response.results);
        setLoadingCategories(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_URL_SERVER}/v1/artists`, axiosHeaders);
        setArtists(response.data.results);
        setLoadingArtists(false);
      } catch (error) {
        console.error("Error fetching /artists: ", error);
      }
    }
    fetchArtists();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loadingCategories || loadingArtists ?
        <Spinner /> :
        <>
          <ModalAddProduct artists={artists} categories={categories} />
          <ProductTable categories={categories} artists={artists}
            columnsData={columnsDataProducts} tableData={tableDataCheck} />
        </>
      }
      <br />
    </Box>
  );
};

export default ProductPage;
