import React from 'react';
import {
	Button,
	Flex,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
	Select,
	Checkbox,
} from '@chakra-ui/react';

import { TextEditor, getInnerHtml } from 'text-editor-react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

const ModalArticle = (props) => {
	const tokenUser = localStorage.getItem('accessToken');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const id = 'my-unique-id';
	const [smallImage, setSmallImage] = React.useState('');
	const [optionalImage, setOptionalImage] = React.useState('');
	const [largeImage, setLargeImage] = React.useState('');
	const [articleData, setArticleData] = React.useState({
		title: '',
		subtitle: '',
		category: '',
		isPublished: false,
		content: '',
		acupression: false,
		artistId: ''
	});
	const handleChange = (e) => {
		const { id, value } = e.target;
		setArticleData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};
	const handleChangeCheck = (e) => {
		const { id, checked } = e.target;
		setArticleData((prevState) => ({
			...prevState,
			[id]: checked,
		}));
	};

	const saveContent = async () => {
		const content = getInnerHtml(id);

		const tempElement = document.createElement('div');
		tempElement.innerHTML = content;

		tempElement.querySelectorAll('[style], [class]').forEach((element) => {
			element.removeAttribute('style');
			element.removeAttribute('class');
		});
		tempElement.querySelectorAll('style').forEach((element) => {
			element.remove();
		});
		const cleanedContent = tempElement.innerHTML;

		let raw;

		if (optionalImage === '') {
			raw = JSON.stringify({
				title: articleData.title,
				subtitle: articleData.subtitle,
				category: articleData.category,
				smallImage: smallImage,
				largeImage: largeImage,
				isPublished: articleData.isPublished,
				content: cleanedContent,
				artistId: articleData.artistId?.length ? articleData.artistId : null
			});
		} else {
			raw = JSON.stringify({
				title: articleData.title,
				subtitle: articleData.subtitle,
				category: articleData.category,
				smallImage: smallImage,
				largeImage: largeImage,
				isPublished: articleData.isPublished,
				content: cleanedContent,
				artistId: articleData.artistId?.length ? articleData.artistId : null,
				optionalImage: optionalImage
			});
		}

		const axiosHeaders = {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: `Bearer ${tokenUser}`,
			},
		};

		try {
			await axios.post(`${process.env.REACT_APP_URL_SERVER}/v1/articles`, raw, axiosHeaders);
			toast.success("L'article a bien été ajouté.", {
				onClose: () => {
					window.location.reload();
				}
			});
		} catch (error) {
			toast.error("Erreur lors de l'ajout de l'article.");
			console.error("Erreur lors de l'ajout de l'article: ", error);
		}
	};

	const uploadSmallImage = async (image) => {
		const formData = new FormData();
		formData.append('image', image);

		const response = await axios.post(
			`https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
			formData
		);
		setSmallImage(response.data.data.url);
	};

	const uploadOptionalImage = async (image) => {
		const formData = new FormData();
		formData.append('image', image);

		const response = await axios.post(
			`https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
			formData
		);
		setOptionalImage(response.data.data.url);
	};

	const uploadLargeImage = async (image) => {
		const formData = new FormData();
		formData.append('image', image);

		const response = await axios.post(
			`https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
			formData
		);
		setLargeImage(response.data.data.url);
	};
	return (
		<>
			<ToastContainer autoClose={2000} />
			<Button
				fontSize="sm"
				variant="brand"
				fontWeight="500"
				style={{ marginLeft: '20px' }}
				onClick={onOpen}
			>
				Ajouter un article
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Ajouter un article</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Input
							id="title"
							defaultValue={articleData.title}
							onChange={handleChange}
							placeholder="Titre de l'article"
						/>
						<Input
							id="subtitle"
							defaultValue={articleData.subtitle}
							onChange={handleChange}
							mt="20px"
							placeholder="Sous-titre de l'article"
						/>
						<div style={{ marginTop: '20px' }}>
							<label htmlFor="largeImage">
								Grande Image de l'article (taille : LargeurxHauteur : 720 x 320)
							</label>
							{largeImage.length === 0 ? (
								<Input
									id="largeImage"
									type="file"
									accept="image/*"
									onChange={(event) => uploadLargeImage(event.target.files[0])}
									placeholder="Lien de l'image"
								/>
							) : (
								<Flex style={{ position: 'relative' }}>
									<img src={largeImage} alt="largeImage" />
									<Button
										colorScheme="red"
										onClick={() => setLargeImage('')}
										style={{ zIndex: '99999', position: 'absolute', top: '0' }}
									>
										supprimer
									</Button>
								</Flex>
							)}
						</div>

						<div style={{ marginTop: '20px' }}>
							<label htmlFor="smallImage">
								Petite Image de l'article (taille : LargeurxHauteur : 352 x 265)
							</label>
							{smallImage.length === 0 ? (
								<Input
									id="smallImage"
									type="file"
									accept="image/*"
									onChange={(event) => uploadSmallImage(event.target.files[0])}
									placeholder="Lien de l'image"
								/>
							) : (
								<Flex style={{ position: 'relative' }}>
									<img src={smallImage} alt="smallImage" />
									<Button
										colorScheme="red"
										onClick={() => setSmallImage('')}
										style={{ zIndex: '99999', position: 'absolute', top: '0' }}
									>
										supprimer
									</Button>
								</Flex>
							)}
						</div>
						<Select
							id="category"
							defaultValue={articleData.category}
							onChange={handleChange}
							mt="20px"
							placeholder="Catégorie"
						>
							<option value="Tips">Tips</option>
							<option value="Culture">Culture</option>
							<option value="Éducation">Éducation</option>
							<option value="Bien-être">Bien-être</option>
							<option value="Balade">Balade</option>
							<option value="Artistes">Artistes</option>
						</Select>
						<Select
							id="artistId"
							defaultValue={articleData.artistId}
							onChange={handleChange}
							mt="20px"
							placeholder="Artiste"
						>
							{props.artists?.map((artist, index) =>
								<option key={index} value={artist.id}>{artist.artistName}</option>
							)}
						</Select>
						<div style={{ marginTop: '40px' }}>
							<ModalHeader style={{ padding: '0px' }}>
								Contenu de l'article
							</ModalHeader>
							<TextEditor
								id={id}
								showHeadings={false}
								editorStyle={{
									fontFamily: 'Epilogue',
									fontWeight: '400',
									fontSize: '12px',
									lineHeight: '15px',
									color: 'black',
									letterSpacing: '0',
								}}
							/>
							<Flex align="center" style={{ marginTop: '40px' }}>
								<Checkbox
									id="acupression"
									defaultChecked={articleData.acupression}
									defaultValue={articleData.acupression}
									onChange={handleChangeCheck}
									colorScheme="brandScheme"
									me="10px"
								/>
								<Text color="blue" fontSize="sm" fontWeight="700">
									Article acupression
								</Text>
							</Flex>
							{articleData.acupression === true && (
								<div style={{ marginTop: '20px' }}>
									<label htmlFor="largeImage">
										Image acupression (taille : Largeur max : 720)
									</label>
									{optionalImage.length === 0 ? (
										<Input
											id="optionalImage"
											type="file"
											accept="image/*"
											onChange={(event) =>
												uploadOptionalImage(event.target.files[0])
											}
											placeholder="Lien de l'image"
										/>
									) : (
										<Flex style={{ position: 'relative' }}>
											<img src={optionalImage} alt="largeImage" />
											<Button
												colorScheme="red"
												onClick={() => setOptionalImage('')}
												style={{
													zIndex: '99999',
													position: 'absolute',
													top: '0',
												}}
											>
												supprimer
											</Button>
										</Flex>
									)}
								</div>
							)}
						</div>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Fermer
						</Button>
						<Button colorScheme="blue" onClick={saveContent}>
							Ajouter l'article
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ModalArticle;
