import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// Custom components
import Card from "components/card/Card";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import ModalViewOrder from "views/admin/orders/ModalViewOrder";

export default function OrderTable(props) {
  const { columnsData, tableData } = props;
  const tokenUser = localStorage.getItem("accessToken");
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, headerGroups, initialState } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  function formatDateFrench(dateTimeString) {
    const dateObj = new Date(dateTimeString);

    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getUTCFullYear();
    const hours = dateObj.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");

    return `Le ${day}/${month}/${year} à ${hours}h${minutes}`;
  }

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/orders?page=${page}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setFilteredOrders(response.results);
        setOrders(response.results);
        setTotalPages(response.totalPages);
        setTotalOrders(response.totalResults);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [page]);

  useEffect(() => {
    const filtered = orders.filter((order) =>
      order.orderId.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
    setFilteredOrders(filtered);
  }, [orders, searchTerm]);

  function handlePrevPage() {
    setPage((prevPage) => prevPage - 1);
  }

  function handleNextPage() {
    setPage((prevPage) => prevPage + 1);
  }

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      mt="40px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Commandes Mr.Nini
        </Text>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Rechercher par numéro de commande"
            variant="flushed"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width={{ sm: "100%", md: "60%", lg: "50%" }}
          />
        </InputGroup>
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {filteredOrders.map((order, index) => (
            <Tr key={index}>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {order.orderId}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {order.firstname}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {order.lastname}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {order.email}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {order.city}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {formatDateFrench(order.createdAt)}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <Text color={textColor} fontSize="sm" fontWeight="700">
                    {order.amount / 100} €
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  {order.paymentStatus === "succeeded" && (
                    <Flex alignItems={"center"} gap={2}>
                      <div
                        style={{
                          backgroundColor: `#10b981`,
                          height: "10px",
                          width: "10px",
                          borderRadius: "100%",
                        }}
                      ></div>
                      <Text color="#10b981" fontSize="sm" fontWeight="700">
                        Validé
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  {order.status === "done" && (
                    <Flex alignItems={"center"} gap={2}>
                      <div
                        style={{
                          backgroundColor: `#10b981`,
                          height: "10px",
                          width: "10px",
                          borderRadius: "100%",
                        }}
                      ></div>
                      <Text color="#10b981" fontSize="sm" fontWeight="700">
                        Traitée
                      </Text>
                    </Flex>
                  )}
                  {order.status === "delivery" && (
                    <Flex alignItems={"center"} gap={2}>
                      <div
                        style={{
                          backgroundColor: `#F59E0B`,
                          height: "10px",
                          width: "10px",
                          borderRadius: "100%",
                        }}
                      ></div>
                      <Text color="#F59E0B" fontSize="sm" fontWeight="700">
                        En cours de livraison
                      </Text>
                    </Flex>
                  )}
                  {order.status === "pending" && (
                    <Flex alignItems={"center"} gap={2}>
                      <div
                        style={{
                          backgroundColor: `red`,
                          height: "10px",
                          width: "10px",
                          borderRadius: "100%",
                        }}
                      ></div>
                      <Text color="red" fontSize="sm" fontWeight="700">
                        En cours de préparation
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Td>
              <Td>
                <Flex align="center">
                  <ModalViewOrder
                    orderId={order.orderId}
                    firstname={order.firstname}
                    lastname={order.lastname}
                    email={order.email}
                    status={order.status}
                    amount={order.amount}
                    paymentId={order.paymentId}
                    paymentStatus={order.paymentStatus}
                    products={order.products}
                    address={order.address}
                    city={order.city}
                    postalCode={order.postalCode}
                    link={order.link}
                    phone={order.phone}
                    id={order.id}
                    key={order.id}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex
        style={{
          justifyContent: "space-between",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Text
          color={textColor}
          fontSize="15px"
          fontWeight="700"
          lineHeight="100%"
          style={{ marginTop: "10px" }}
        >
          {searchTerm
            ? `Nombre de commandes trouvées : ${filteredOrders.length}`
            : `Nombre total de commandes : ${totalOrders}`}
        </Text>
        <div>
          <Flex style={{ gap: "20px" }}>
            <Button
              variant="brand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              onClick={handlePrevPage}
              disabled={page === 1}
              style={{ padding: "0px" }}
            >
              <Icon w="24px" h="24px" color={"white"} as={MdNavigateBefore} />
            </Button>
            <Text
              color={textColor}
              fontSize="15px"
              fontWeight="700"
              lineHeight="100%"
              style={{ marginTop: "10px" }}
            >
              Page {page} sur {totalPages}
            </Text>
            <Button
              variant="brand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              onClick={handleNextPage}
              disabled={page === totalPages}
              style={{ padding: "0px" }}
            >
              <Icon w="24px" h="24px" color={"white"} as={MdNavigateNext} />
            </Button>
          </Flex>
        </div>
      </Flex>
    </Card>
  );
}
