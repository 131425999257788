import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';

// Vérifier si l'utilisateur est authentifié
const isAuthenticated = async () => {
  const tokenUser = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!tokenUser) {
    return Promise.resolve(false);
  }

  try {
    const decodedToken = jwt_decode(tokenUser);
    const subject = decodedToken.sub;

    const myHeaders = new Headers();
    myHeaders.append('Access-Control-Allow-Origin', '*');
    myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
    myHeaders.append('Authorization', `Bearer ${tokenUser}`);
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    return fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/users/${subject}`,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();
        if (
          data.code === 403 ||
          data.code === 404
        ) {
          return false;
        }

        if (data.code === 401) {
          try {
            const response = await axios.post(`${process.env.REACT_APP_URL_SERVER}/v1/auth/refresh-tokens`, {
              refreshToken,
            });
            localStorage.setItem('accessToken', response.tokens.access.token);
            return true;
          } catch(error) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
          }
        }

        if (data.role === 'admin') {
          return true;
        }
      });
  } catch (error) {
    console.error('Token invalide ou expiré :', error);
    return Promise.resolve(false);
  }
};
export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const history = useHistory();
	isAuthenticated().then((isAuthenticated) => {
		if (!isAuthenticated) {
			history.push('/auth/sign-in');
		}
	});
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? <Component {...props} /> : <Redirect to="/sign-in" />
			}
		/>
	);
};

export default ProtectedRoute;
