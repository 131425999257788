import { Box } from "@chakra-ui/react";
import React from "react";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import { columnsDataOrders } from "../default/variables/columnsData";
import OrderTable from "../dataTables/components/OrderTable";

const OrderPage = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <OrderTable columnsData={columnsDataOrders} tableData={tableDataCheck} />
      <br />
    </Box>
  );
};

export default OrderPage;
