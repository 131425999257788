/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { CgTrash } from "react-icons/cg";
import ModalEditCategory from "views/admin/categories/ModalEditCategory";
export default function ProductTable(props) {
  const { columnsData, tableData } = props;
  const tokenUser = localStorage.getItem("accessToken");
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, headerGroups, initialState } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/categories?page=${page}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setCategories(response.results);
        setTotalPages(response.totalPages);
        setTotalProducts(response.totalResults);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [page]);

  const deleteProduct = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/categories/${id}`,
      requestOptions
    )
      .then((response) => {
        return response;
      })
      .then((response) => {
        window.location.reload();
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  function handlePrevPage() {
    setPage((prevPage) => prevPage - 1);
  }

  function handleNextPage() {
    setPage((prevPage) => prevPage + 1);
  }
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      mt="40px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Catégories Produits Mr.Nini
        </Text>
        {/* <Menu /> */}
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {categories.map((category, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Flex align="center">
                    <Text color={textColor} fontSize="sm" fontWeight="700">
                      {category.category}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  <Flex align="center">
                    <ModalEditCategory
                      category={category.category}
                      descriptionFr={category.descriptionFr}
                      descriptionEn={category.descriptionEn}
                      id={category.id}
                      key={index}
                    />
                    <Icon
                      w="24px"
                      h="24px"
                      me="5px"
                      color={"red.500"}
                      style={{ cursor: "pointer" }}
                      as={CgTrash}
                      onClick={() => {
                        deleteProduct(category.id);
                      }}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex
        style={{
          justifyContent: "space-between",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Text
          color={textColor}
          fontSize="15px"
          fontWeight="700"
          lineHeight="100%"
          style={{ marginTop: "10px" }}
        >
          Nombre de résultat : {totalProducts} catégories produits.
        </Text>
        <div>
          <Flex style={{ gap: "20px" }}>
            <Button
              variant="brand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              onClick={handlePrevPage}
              disabled={page === 1}
              style={{ padding: "0px" }}
            >
              <Icon w="24px" h="24px" color={"white"} as={MdNavigateBefore} />
            </Button>
            <Text
              color={textColor}
              fontSize="15px"
              fontWeight="700"
              lineHeight="100%"
              style={{ marginTop: "10px" }}
            >
              Page {page} sur {totalPages}
            </Text>
            <Button
              variant="brand"
              color="white"
              fontSize="sm"
              fontWeight="500"
              borderRadius="70px"
              onClick={handleNextPage}
              disabled={page === totalPages}
              style={{ padding: "0px" }}
            >
              <Icon w="24px" h="24px" color={"white"} as={MdNavigateNext} />
            </Button>
          </Flex>
        </div>
      </Flex>
    </Card>
  );
}
