import { Box } from "@chakra-ui/react";
import React from "react";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import { columnsDataArtists } from "../default/variables/columnsData";
import ModalAddArtist from "./ModalAddArtist";
import ArtistTable from "../dataTables/components/ArtistTable";

const ArtistPage = () => {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <ModalAddArtist />
            <ArtistTable
                columnsData={columnsDataArtists}
                tableData={tableDataCheck}
            />
            <br />
        </Box>
    );
};

export default ArtistPage;
