import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormLabel,
  Stack,
  FormControl,
  Icon,
  Textarea,
} from "@chakra-ui/react";

import axios from "axios";
import { FaPencilAlt } from "react-icons/fa";

const ModalEditCategory = (props) => {
  const tokenUser = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [categoryData, setCategoryData] = React.useState({
    category: props.category,
    descriptionFr: props.descriptionFr,
    descriptionEn: props.descriptionEn,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({
      ...categoryData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL_SERVER}/v1/categories/${props.id}`,
        categoryData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${tokenUser}`,
          },
        }
      );
      if (response.status === 200) {
        setCategoryData({
          category: "",
          descriptionFr: "",
          descriptionEn: "",
        });
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit : ", error);
    }
  };
  return (
    <>
      <Icon
        w="20px"
        h="20px"
        me="5px"
        // color={"red.500"}
        style={{ cursor: "pointer" }}
        as={FaPencilAlt}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier la catégorie produit</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3} mt={5}>
                <FormControl>
                  <FormLabel>Nom de la catégorie produit</FormLabel>
                  <Input
                    type="text"
                    name="category"
                    placeholder="Nom de la catégorie produit"
                    value={categoryData.category}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description en français</FormLabel>
                  <Textarea
                    type="text"
                    name="descriptionFr"
                    placeholder="Description en français"
                    value={categoryData.descriptionFr}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Description en anglais</FormLabel>
                  <Textarea
                    type="text"
                    name="descriptionEn"
                    placeholder="Description en anglais"
                    value={categoryData.descriptionEn}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Mettre à jour la catégorie produit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalEditCategory;
