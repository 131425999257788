/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Select,
} from "@chakra-ui/react";

import { getInnerHtml } from "text-editor-react";
import axios from "axios";
import { toast } from "react-toastify";
import formatHtml from "utils/formatHtml";

const ModalEdit = (props) => {
  const tokenUser = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const id = "my-unique-id";
  const [smallImageProps, setSmallImage] = React.useState("");
  const [optionalImageProps, setOptionalImage] = React.useState("");
  const [largeImageProps, setLargeImage] = React.useState("");
  const [articleData, setArticleData] = React.useState({
    title: "",
    subtitle: "",
    category: "",
    content: "",
    artistId: props.artistId,
  });

  const axiosHeaders = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${tokenUser}`,
    },
  };

  useEffect(() => {
    setSmallImage(props.smallImage);
    setLargeImage(props.largeImage);
    if (props.optionalImage !== undefined) {
      setOptionalImage(props.optionalImage);
    }
  }, []);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setArticleData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
  const saveContent = async () => {
    const content = getInnerHtml(id);

    const tempElement = document.createElement("div");
    tempElement.innerHTML = content;

    tempElement.querySelectorAll("[style], [class]").forEach((element) => {
      element.removeAttribute("style");
      element.removeAttribute("class");
    });
    tempElement.querySelectorAll("style").forEach((element) => {
      element.remove();
    });

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    let raw;
    if (optionalImageProps === "") {
      raw = JSON.stringify({
        title: articleData.title === "" ? props.title : articleData.title,
        subtitle:
          articleData.subtitle === "" ? props.subtitle : articleData.subtitle,
        category:
          articleData.category === "" ? props.category : articleData.category,
        smallImage: smallImageProps,
        largeImage: largeImageProps,
        // isPublished: articleData.isPublished,
        content: content,
        artistId: articleData.artistId?.length ? articleData.artistId : null,
      });
    } else {
      raw = JSON.stringify({
        title: articleData.title === "" ? props.title : articleData.title,
        subtitle:
          articleData.subtitle === "" ? props.subtitle : articleData.subtitle,
        category:
          articleData.category === "" ? props.category : articleData.category,
        smallImage: smallImageProps,
        largeImage: largeImageProps,
        optionalImage: optionalImageProps,
        content: content,
        artistId: articleData.artistId?.length ? articleData.artistId : null,
      });
    }

    try {
      await axios.patch(
        `${process.env.REACT_APP_URL_SERVER}/v1/articles/${props.id}`,
        raw,
        axiosHeaders
      );
      toast.success("L'article a bien été modifié.", {
        onClose: () => {
          window.location.reload();
        },
      });
    } catch (error) {
      toast.error("Erreur lors de la modification de l'article.");
      console.error("Erreur lors de la modification de l'article: ", error);
    }
  };

  const uploadOptionalImage = async (image) => {
    const formData = new FormData();
    formData.append("image", image);

    const response = await axios.post(
      `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
      formData
    );
    setOptionalImage(response.data.data.url);
  };

  const uploadSmallImage = async (image) => {
    const formData = new FormData();
    formData.append("image", image);

    const response = await axios.post(
      `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
      formData
    );
    setSmallImage(response.data.data.url);
  };

  const uploadLargeImage = async (image) => {
    const formData = new FormData();
    formData.append("image", image);

    const response = await axios.post(
      `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
      formData
    );
    setLargeImage(response.data.data.url);
  };
  return (
    <>
      <Button
        fontSize="sm"
        colorScheme={"yellow"}
        color="white"
        fontWeight="500"
        px="24px"
        py="5px"
        // style={{ marginLeft: '20px' }}
        onClick={onOpen}
      >
        Modifier
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifier l'article</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              id="title"
              defaultValue={props.title}
              onChange={handleChange}
              placeholder="Titre de l'article"
            />
            <Input
              id="subtitle"
              defaultValue={props.subtitle}
              onChange={handleChange}
              mt="20px"
              placeholder="Sous-titre de l'article"
            />
            <div style={{ marginTop: "20px" }}>
              <label htmlFor="largeImage">
                Grande Image de l'article (taille : LargeurxHauteur : 720 x 320)
              </label>
              {largeImageProps.length === 0 ? (
                <Input
                  id="largeImage"
                  type="file"
                  accept="image/*"
                  onChange={(event) => uploadLargeImage(event.target.files[0])}
                  placeholder="Lien de l'image"
                />
              ) : (
                <Flex style={{ position: "relative" }}>
                  <img src={largeImageProps} alt="largeImage" />
                  <Button
                    colorScheme="red"
                    onClick={() => setLargeImage("")}
                    style={{ zIndex: "99999", position: "absolute", top: "0" }}
                  >
                    supprimer
                  </Button>
                </Flex>
              )}
            </div>

            <div style={{ marginTop: "20px" }}>
              <label htmlFor="smallImage">
                Petite Image de l'article (taille : LargeurxHauteur : 352 x 265)
              </label>
              {smallImageProps.length === 0 ? (
                <Input
                  id="smallImage"
                  type="file"
                  accept="image/*"
                  onChange={(event) => uploadSmallImage(event.target.files[0])}
                  placeholder="Lien de l'image"
                />
              ) : (
                <Flex style={{ position: "relative" }}>
                  <img src={smallImageProps} alt="smallImage" />
                  <Button
                    colorScheme="red"
                    onClick={() => setSmallImage("")}
                    style={{ zIndex: "99999", position: "absolute", top: "0" }}
                  >
                    supprimer
                  </Button>
                </Flex>
              )}
            </div>
            <Select
              id="category"
              defaultValue={props.category}
              onChange={handleChange}
              mt="20px"
              cursor={"pointer"}
              //placeholder="Catégorie"
            >
              <option value="Tips">Tips</option>
              <option value="Culture">Culture</option>
              <option value="Éducation">Éducation</option>
              <option value="Bien-être">Bien-être</option>
              <option value="Balade">Balade</option>
              <option value="Artistes">Artistes</option>
            </Select>
            <Select
              id="artistId"
              defaultValue={articleData.artistId}
              onChange={handleChange}
              mt="20px"
              placeholder="Artiste"
            >
              {props.artists?.map((artist, index) => (
                <option key={index} value={artist.id}>
                  {artist.artistName}
                </option>
              ))}
            </Select>
            <div style={{ marginTop: "40px" }}>
              <ModalHeader style={{ padding: "0px" }}>
                Contenu de l'article
              </ModalHeader>
              <div
                className="react-text-editor"
                id="my-unique-id"
                contentEditable={true}
                style={{
                  fontFamily: "Epilogue",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "black",
                  letterSpacing: "0px",
                }}
                dangerouslySetInnerHTML={{
                  __html: formatHtml(props.content),
                }}
              ></div>
              {props.optionalImage !== undefined &&
              props.optionalImage !== "" ? (
                <div style={{ marginTop: "40px" }}>
                  <label htmlFor="optionalImage">
                    Image de l'acupression (taille : Largeur max : 720)
                  </label>
                  {optionalImageProps.length === 0 ? (
                    <Input
                      id="optionalImage"
                      type="file"
                      accept="image/*"
                      onChange={(event) =>
                        uploadOptionalImage(event.target.files[0])
                      }
                      placeholder="Lien de l'image"
                    />
                  ) : (
                    <Flex style={{ position: "relative" }}>
                      <img src={optionalImageProps} alt="largeImage" />
                      <Button
                        colorScheme="red"
                        onClick={() => setOptionalImage("")}
                        style={{
                          zIndex: "99999",
                          position: "absolute",
                          top: "0",
                        }}
                      >
                        supprimer
                      </Button>
                    </Flex>
                  )}
                </div>
              ) : null}
            </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Annuler
            </Button>
            <Button colorScheme="blue" onClick={saveContent}>
              Mettre à jour l'article
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalEdit;
