import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	Router,
} from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RTLLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import SignIn from 'views/auth/signIn';
import ProtectedRoute from 'views/auth/auth';
import Register from 'views/auth/register';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<BrowserRouter>
					<Switch>
					<Route path={`/auth/register`} component={Register} />
					<Route path={`/auth/sign-in`} component={SignIn} />
						<ProtectedRoute path={`/admin`} component={AdminLayout} />
						<Route path={`/rtl`} component={RTLLayout} />
						<Route path={'/'} component={SignIn} />
					</Switch>
				</BrowserRouter>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
