import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdOutlineShoppingCart,
  MdPersonSearch,
  MdOutlineTipsAndUpdates,
  MdCategory,
} from "react-icons/md";

import { SiDatadog } from "react-icons/si";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Tips from "views/admin/tips";
import DataTables from "views/admin/dataTables";
import "./fonts/Epilogue-Regular.ttf";

// Auth Imports
import Community from "views/admin/community";
import { FaShoppingBag } from "react-icons/fa";
import ProductPage from "views/admin/products";
import CategoryPage from "views/admin/categories";
import { FaFileInvoiceDollar } from "react-icons/fa";
import OrderPage from "views/admin/orders";
import ArtistPage from "views/admin/artists";

const routes = [
  {
    name: "Tableau de bord Mr. Nini",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Articles",
    layout: "/admin",
    path: "/articles",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Community",
    layout: "/admin",
    path: "/community",
    icon: <Icon as={SiDatadog} width="20px" height="20px" color="inherit" />,
    component: Community,
    secondary: true,
  },
  {
    name: "Tips",
    layout: "/admin",
    path: "/tips",
    icon: (
      <Icon
        as={MdOutlineTipsAndUpdates}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Tips,
    secondary: true,
  },
  {
    name: "Utilisateurs",
    layout: "/admin",
    icon: (
      <Icon as={MdPersonSearch} width="20px" height="20px" color="inherit" />
    ),
    path: "/users",
    component: DataTables,
  },
  {
    name: "Produits",
    layout: "/admin",
    icon: (
      <Icon as={FaShoppingBag} width="20px" height="20px" color="inherit" />
    ),
    path: "/products",
    component: ProductPage,
  },
  {
    name: "Catégories Produit",
    layout: "/admin",
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    path: "/categories",
    component: CategoryPage,
  },
  {
    name: "Artistes",
    layout: "/admin",
    icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
    path: "/artists",
    component: ArtistPage,
  },
  {
    name: "Commandes",
    layout: "/admin",
    icon: <Icon as={FaFileInvoiceDollar} width="20px" height="20px" color="inherit" />,
    path: "/orders",
    component: OrderPage,
  },
];

export default routes;
