// Chakra imports
import {
	Box,
	Button,
	Flex,
	Image,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import ModalEditTips from 'components/modal/modalEditTips';
import ModalTips from 'components/modal/modalTips.js';
// Assets
import React from 'react';

export default function Tips(props) {
	const {
		image,
		category,
		fullname,
		content,
		id,
		count,
		typeWrite,
		isPublished,
	} = props;
	const tokenUser = localStorage.getItem('accessToken');
	const textColor = useColorModeValue('navy.700', 'white');

	const deleteTips = () => {
		const myHeaders = new Headers();
		myHeaders.append('Access-Control-Allow-Origin', '*');
		myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
		myHeaders.append('Authorization', `Bearer ${tokenUser}`);
		const requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
		};
		fetch(`${process.env.REACT_APP_URL_SERVER}/v1/writes/${id}`, requestOptions)
			.then((response) => {
				return response;
			})
			.then((response) => {
				window.location.reload();
			})
			.catch(function (error) {
				console.error(error);
			});
	};
	return (
		<Card p="20px" style={{ maxHeight: '500px' }}>
			<Flex direction={{ base: 'column' }} justify="center">
				<Box mb={{ base: '20px', '2xl': '20px' }} position="relative">
					<Image
						src={image}
						style={{
							height: '150px',
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							margin: 'auto',
						}}
						borderRadius="20px"
					/>
					<Button
						position="absolute"
						bg="white"
						_hover={{ bg: 'whiteAlpha.900' }}
						_active={{ bg: 'white' }}
						_focus={{ bg: 'white' }}
						p="5px !important"
						top="14px"
						left="14px"
						borderRadius="8px"
					>
						<Text
							color={textColor}
							fontSize={{
								base: 'small',
								md: 'small',
								lg: 'small',
								xl: 'small',
								'2xl': 'small',
								'3xl': 'small',
							}}
							fontWeight="bold"
						>
							{category}
						</Text>
					</Button>
				</Box>
				<Flex flexDirection="column" justify="space-between" h="100%">
					<Flex
						justify="space-between"
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row',
						}}
						mb="auto"
					>
						<Flex
							direction="column"
							style={{ marginBottom: '50px', width: '100%' }}
						>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg',
								}}
								mb="5px"
								fontWeight="bold"
								me="14px"
								className="titleTips"
							>
								{fullname}
							</Text>
							<Text
								color={textColor}
								className="descriptionTips"
								fontSize={{
									base: 'sm',
								}}
								fontWeight="400"
								me="14px"
							>
								{category === 'Review' ? `${count} étoiles sur 5 étoiles` : ''}
							</Text>
							<Text
								color="secondaryGray.600"
								className="descriptionTips"
								fontSize={{
									base: 'sm',
								}}
								fontWeight="400"
								me="14px"
								mb="25px"
							>
								{content}
							</Text>
							<Text
								color={isPublished === true ? 'green' : 'red'}
								className="descriptionTips"
								fontSize={{
									base: 'lg',
								}}
								fontWeight="bold"
							>
								{isPublished === true ? 'Publié' : 'Non publié'}
							</Text>
						</Flex>
					</Flex>
					<Flex
						align="start"
						justify="space-between"
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row',
						}}
						mt="0px"
					>
						<Button
							colorScheme={'red'}
							color="white"
							fontSize="sm"
							fontWeight="500"
							borderRadius="70px"
							px="24px"
							py="5px"
							onClick={() => {
								deleteTips(id);
							}}
						>
							Supprimer
						</Button>
						<ModalEditTips
							id={id}
							typeWrite={typeWrite}
							isPublished={isPublished}
							image={image}
							fullname={fullname}
							content={content}
							category={category}
							count={count}
						/>

						<ModalTips
							id={id}
							typeWrite={typeWrite}
							isPublished={isPublished}
							image={image}
							fullname={fullname}
							content={content}
							category={category}
							count={count}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
