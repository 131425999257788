// Chakra imports
import {
	Box,
	Button,
	Flex,
	Image,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
// Assets
import React from 'react';

export default function Community(props) {
	const { image, id } = props;
	const tokenUser = localStorage.getItem('accessToken');

	const deleteTips = () => {
		const myHeaders = new Headers();
		myHeaders.append('Access-Control-Allow-Origin', '*');
		myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
		myHeaders.append('Authorization', `Bearer ${tokenUser}`);
		const requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
		};
		fetch(`${process.env.REACT_APP_URL_SERVER}/v1/community/${id}`, requestOptions)
			.then((response) => {
				return response;
			})
			.then((response) => {
				window.location.reload();
			})
			.catch(function (error) {
				console.error(error);
			});
	};
	return (
		<Card p="20px" style={{ maxHeight: '500px' }}>
			<Flex direction={{ base: 'column' }} justify="center">
				<Box mb={{ base: '20px', '2xl': '20px' }} position="relative">
					<Image
						src={image}
						style={{
							height: '150px',
							justifyContent: 'center',
							alignItems: 'center',
							display: 'flex',
							margin: 'auto',
						}}
						borderRadius="20px"
					/>
				</Box>
				<Flex flexDirection="column" justify="space-between" h="100%">
					<Flex
						align="start"
						justify="center"
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row',
						}}
						mt="25px"
					>
						<Button
							colorScheme={'red'}
							color="white"
							fontSize="sm"
							fontWeight="500"
							borderRadius="70px"
							px="24px"
							py="5px"
							onClick={() => {
								deleteTips(id);
							}}
						>
							Supprimer
						</Button>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
