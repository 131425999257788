import React from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
} from '@chakra-ui/react';

import axios from 'axios';

const ModalCommunity = () => {
	const tokenUser = localStorage.getItem('accessToken');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [smallImage, setSmallImage] = React.useState('');

	const saveContent = () => {
		const myHeaders = new Headers();
		myHeaders.append('Access-Control-Allow-Origin', '*');
		myHeaders.append('Content-Type', 'application/json; charset=UTF-8');
		myHeaders.append('Authorization', `Bearer ${tokenUser}`);
		const raw = JSON.stringify({
			image: smallImage,
		});
		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
		};
		fetch(`${process.env.REACT_APP_URL_SERVER}/v1/community`, requestOptions)
			.then((response) => {
				return response;
			})
			.then((response) => {
				window.location.reload();
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	const uploadSmallImage = async (image) => {
		const formData = new FormData();
		formData.append('image', image);

		const response = await axios.post(
			`https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_IMG_KEY}`,
			formData
		);
		setSmallImage(response.data.data.url);
	};
	return (
		<>
			<Button
				fontSize="sm"
				variant="brand"
				fontWeight="500"
				style={{ marginLeft: '20px' }}
				onClick={onOpen}
			>
				Ajouter un photo
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="full">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Ajouter une photo</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<div style={{ marginTop: '20px' }}>
							<label htmlFor="smallImage">
								Photo communautaire (taille : LargeurxHauteur : 400 x 560)
							</label>
							{smallImage.length === 0 ? (
								<Input
									id="smallImage"
									type="file"
									accept="image/*"
									onChange={(event) => uploadSmallImage(event.target.files[0])}
									placeholder="Lien de l'image"
								/>
							) : (
								<Flex style={{ position: 'relative' }}>
									<img src={smallImage} alt="smallImage" />
									<Button
										colorScheme="red"
										onClick={() => setSmallImage('')}
										style={{ zIndex: '99999', position: 'absolute', top: '0' }}
									>
										supprimer
									</Button>
								</Flex>
							)}
						</div>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" mr={3} onClick={onClose}>
							Fermer
						</Button>
						<Button colorScheme="blue" onClick={saveContent}>
							Ajouter la photo
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ModalCommunity;
