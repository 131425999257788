/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
// Chakra imports
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React, { useEffect, useState } from "react";

export default function TotalSpent(props) {
  const { ...rest } = props;
  const tokenUser = localStorage.getItem("accessToken");
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");
    myHeaders.append("Authorization", `Bearer ${tokenUser}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `${process.env.REACT_APP_URL_SERVER}/v1/orders/counts/day`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log(response);
        setTotal(response.totalAmount);
        setOrders(response.dailyAmounts);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  let lineChartDataTotalSpent = null;
  let xAxisLabels = [];

  if (orders.length > 0) {
    lineChartDataTotalSpent = [
      {
        name: "Montant",
        data: orders.map((item) => item.amount),
      },
    ];
    const today = new Date();

    xAxisLabels = orders.map((item, index) => {
      const date = new Date(today);
      date.setDate(date.getDate() - (orders.length - index - 1));
      const options = { weekday: "short", month: "short", day: "numeric" };
      return date.toLocaleDateString("fr-FR", options);
    });
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const lineChartOptionsTotalSpent = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric",
      categories: xAxisLabels,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function(value) {
          return value + " €";
        }
      }
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#39B8FF"],
  };
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justifyContent={'space-between'} me="20px" mt="18px">
        <Text
          color={textColor}
          fontSize="18px"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          Montant des 30 derniers jours (€)
        </Text>
        <Text
          color={textColor}
          fontSize="18px"
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          Montant total {total} €
        </Text>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="260px" minW="100%" mt="auto">
          <LineChart
            chartData={lineChartDataTotalSpent}
            chartOptions={lineChartOptionsTotalSpent}
          />
        </Box>
      </Flex>
    </Card>
  );
}
