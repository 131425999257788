/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormLabel,
  Image,
  Icon,
  Text,
  Flex,
  FormControl,
  Input,
} from "@chakra-ui/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { FaEye } from "react-icons/fa";
import { RxSlash } from "react-icons/rx";

const ModalViewOrder = (props) => {
  const tokenUser = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [productData, setProductData] = useState({
    link: props.link || "",
  });

  const changeStatus = (status) => async () => {
    if (status === "delivery" && productData.link === "") {
      toast.error("Veuillez renseigner le lien de tracking de la commande");
      return;
    }
    try {
      let data = {};
      if (status === "delivery") {
        data = {
          status: status,
          link: productData.link,
        };
      } else {
        data = {
          status: status,
        };
      }
      const response = await axios.patch(
        `${process.env.REACT_APP_URL_SERVER}/v1/orders/${props.id}`,
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${tokenUser}`,
          },
        }
      );
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Icon
        w="20px"
        h="20px"
        me="5px"
        style={{ cursor: "pointer" }}
        as={FaEye}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Commande de {props.firstname} {props.lastname} - {props.orderId}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex alignItems="center" mb={4} gap={2}>
              <Text fontWeight="700">Statut de la commande : </Text>
              {props.status === "done" && (
                <Flex alignItems={"center"} gap={2}>
                  <div
                    style={{
                      backgroundColor: `#10b981`,
                      height: "10px",
                      width: "10px",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <Text color="#10b981" fontSize="sm" fontWeight="700">
                    Traitée
                  </Text>
                </Flex>
              )}
              {props.status === "delivery" && (
                <Flex alignItems={"center"} gap={2}>
                  <div
                    style={{
                      backgroundColor: `#F59E0B`,
                      height: "10px",
                      width: "10px",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <Text color="#F59E0B" fontSize="sm" fontWeight="700">
                    En cours de livraison
                  </Text>
                </Flex>
              )}
              {props.status === "pending" && (
                <Flex alignItems={"center"} gap={2}>
                  <div
                    style={{
                      backgroundColor: `red`,
                      height: "10px",
                      width: "10px",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <Text color="red" fontSize="sm" fontWeight="700">
                    En cours de préparation
                  </Text>
                </Flex>
              )}
            </Flex>
            <hr />
            <FormLabel fontSize={20} fontWeight={600} mt={5}>
              Informations Client
            </FormLabel>
            <FormLabel color={"gray"} fontWeight={400} mt={-2}>
              Section des informations du client
            </FormLabel>
            <Text fontWeight="700">
              Prénom : <Text as="span">{props.firstname}</Text>
            </Text>
            <Text fontWeight="700">
              Nom de famille : <Text as="span">{props.lastname}</Text>
            </Text>
            <Text fontWeight="700">
              Adresse e-mail : <Text as="span">{props.email}</Text>
            </Text>
            <Text fontWeight="700">
              Numéro de téléphone : <Text as="span">{props.phone}</Text>
            </Text>
            <hr style={{ marginTop: "20px" }} />
            <FormLabel mt={5} fontSize={20} fontWeight={600}>
              Informations Commande
            </FormLabel>
            <FormLabel color={"gray"} fontWeight={400} mt={-2}>
              Section des produits commandés
            </FormLabel>
            {props.products.map((product, index) => {
              return (
                <Box key={index} mb={5} mt={5}>
                  <Flex gap={4} alignItems={"center"} mb={4}>
                    <Image
                      src={product.image}
                      alt="product"
                      w="100px"
                      h="100px"
                      objectFit={"cover"}
                      borderRadius="5px"
                    />
                    <div>
                      <Text fontWeight="700">
                        Nom du produit : <Text as="span">{product.title}</Text>
                      </Text>
                      <Text fontWeight="700">
                        Sous-titre du produit :{" "}
                        <Text as="span">{product.subtitle}</Text>
                      </Text>
                      <Flex alignItems={"center"} gap={2}>
                        <Text fontWeight="700">Couleur du produit :</Text>
                        {!product.noColors ? (
                          <div
                            style={{
                              backgroundColor: `${product.colors}`,
                              height: "15px",
                              width: "15px",
                              borderRadius: "100%",
                            }}
                          ></div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "transparent",
                              height: "15px",
                              width: "15px",
                              borderRadius: "100%",
                              border: "1px black solid",
                            }}
                          >
                            <RxSlash size={14} color="red" />
                          </div>
                        )}
                      </Flex>

                      <Text fontWeight="700">
                        Prix : <Text as="span">{product.price} €</Text>
                      </Text>
                      <Text fontWeight="700">
                        Quantité : <Text as="span">{product.quantity}</Text>
                      </Text>
                    </div>
                  </Flex>
                  <hr style={{ width: "" }} />
                </Box>
              );
            })}
            <Flex align="center" mb={5}>
              <Text fontWeight="700">
                Total : <Text as="span">{props.amount / 100} €</Text>
              </Text>
            </Flex>
            <hr />
            <FormLabel mt={5} fontSize={20} fontWeight={600}>
              Informations Livraison
            </FormLabel>
            <FormLabel color={"gray"} fontWeight={400} mt={-2}>
              Section sur les informations de livraison
            </FormLabel>
            <Text fontWeight="700">
              Adresse de livraison : <Text as="span">{props.address}</Text>
            </Text>
            <Text fontWeight="700">
              Ville : <Text as="span">{props.city}</Text>
            </Text>
            <Text fontWeight="700">
              Code postal : <Text as="span">{props.postalCode}</Text>
            </Text>
            {props.link && (
              <Text fontWeight="700" mb={5}>
                Lien de tracking de la livraison :{" "}
                <a href={props.link} target="_blank" rel="noreferrer">
                  {props.link}
                </a>
              </Text>
            )}
            <hr />
            <FormLabel mt={5} fontSize={20} fontWeight={600}>
              Informations Paiement
            </FormLabel>
            <FormLabel color={"gray"} fontWeight={400} mt={-2}>
              Section sur les informations de paiement
            </FormLabel>
            <Flex gap={2} align="center">
              <Text fontWeight="700">Statut du paiement :</Text>
              {props.paymentStatus === "succeeded" && (
                <Flex alignItems={"center"} gap={2}>
                  <div
                    style={{
                      backgroundColor: `#10b981`,
                      height: "10px",
                      width: "10px",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <Text color="#10b981" fontSize="sm" fontWeight="700">
                    Validé
                  </Text>
                </Flex>
              )}
            </Flex>
            <Button
              onClick={() =>
                window.open(
                  `https://dashboard.stripe.com/test/payments/${props.paymentId}`,
                  "_blank"
                )
              }
              mt={2}
              colorScheme="blue"
            >
              Voir le paiement
            </Button>
            {props.status === "pending" && (
              <FormControl mt={4}>
                <FormLabel fontWeight={700} color={"red"}>
                  Lien tracking de la livraison
                </FormLabel>
                <Input
                  type="text"
                  name="link"
                  placeholder="Lien"
                  value={productData.link}
                  onChange={(e) =>
                    setProductData({ ...productData, link: e.target.value })
                  }
                  required
                />
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            {props.status === "pending" && (
              <Button
                bgColor="#F59E0B"
                color="white"
                onClick={changeStatus("delivery")}
              >
                Passer la commande en "En cours de livraison"
              </Button>
            )}
            {props.status === "delivery" && (
              <Button
                bgColor="#10b981"
                color="white"
                onClick={changeStatus("done")}
              >
                Passer la commande en "Traitée"
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalViewOrder;
