export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataCategories = [
  {
    Header: "Catégorie",
    accessor: "category",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export const columnsDataArtists = [
  {
    Header: "Artiste",
    accessor: "artistName",
  },
  {
    Header: "Prénom",
    accessor: "firsnamt",
  },
  {
    Header: "Nom",
    accessor: "lastname",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export const columnsDataOrders = [
  {
    Header: "Num. commande",
    accessor: "orderId",
  },
  {
    Header: "Prénom",
    accessor: "firstname",
  },
  {
    Header: "Nom",
    accessor: "lastname",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Ville",
    accessor: "city",
  },
  {
    Header: "Date",
    accessor: "createdAt",
  },
  {
    Header: "Montant",
    accessor: "amount",
  },
  {
    Header: "Paiement",
    accessor: "paymentStatus",
  },
  {
    Header: "Statut",
    accessor: "status",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];

export const columnsDataProducts = [
  {
    Header: "Nom",
    accessor: "title",
  },
  {
    Header: "Sous-titre",
    accessor: "subtitle",
  },
  {
    Header: "Catégorie",
    accessor: "category",
  },
  {
    Header: "Artiste",
    accessor: "artistId",
  },
  {
    Header: "Couleurs",
    accessor: "colors",
  },
  {
    Header: "Quantité",
    accessor: "stock",
  },
  {
    Header: "Statut",
    accessor: "stocks",
  },
  {
    Header: "Prix",
    accessor: "price",
  },
  {
    Header: "Actions",
    accessor: "actions",
  },
];
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
